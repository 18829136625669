// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_wrapper__6I8b9 {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Layout_header__XfobK {
  display: flex;
  align-items: center;
}
.Layout_menu__CA7cF {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Layout/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".wrapper {\n  margin: 0 auto;\n  padding: 0 40px;\n  max-width: 1280px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n.menu {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Layout_wrapper__6I8b9`,
	"header": `Layout_header__XfobK`,
	"menu": `Layout_menu__CA7cF`
};
export default ___CSS_LOADER_EXPORT___;
