import React from 'react'
import './Favorites.css'

export default function Favorites() {
  return (
    <div className='Favorites'>Favorites
        <h1>Favorites</h1>
    </div>
  )
}
