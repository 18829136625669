import React from "react";
import "./Invertors.css";

const Invertors = () => {
    return (
        <div className="Invertors">
            <h1>Invertors</h1>
            <p>=/</p>
        </div>
    )
}

export default Invertors;