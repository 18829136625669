// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelFavIcon_imgSize__ab5dr{
    width: 30px;
    height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/PanelFavIcon/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".imgSize{\n    width: 30px;\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgSize": `PanelFavIcon_imgSize__ab5dr`
};
export default ___CSS_LOADER_EXPORT___;
