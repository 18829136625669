import React from 'react';
import './sale.css';

const Sale = () => {
  return (
    <div className="sale">
      <h1>Sale</h1>
    </div>
  );
};

export default Sale;
