// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Panel_panelContainer__dEUQ8 {
    background-color: rgb(20, 20, 20);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    max-width: 284px;
}

.Panel_panelTextContainer__zi8Gz {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}

.Panel_panelTextContainer__zi8Gz p {
    margin: 0;
}

.Panel_panelImg__xfxaW {
    width: 195px;
    height: 234px;
    object-fit: cover;
}

.Panel_redButton__hlFZy {
    background-color: #F60109;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
}

.Panel_seeDetails__YMwY5 {
    background-color: black;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
}

.Panel_panelInfoTechnology__Jk\\+Tz {
    display: flex;
}

.Panel_panelInfoTechnologyValue__n3JUj {
    margin-top: 4px;
    padding-left: 4px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 11px;
}

.Panel_panelInfoId__ET-nW {
    display: flex;
    justify-content: space-between;
}

.Panel_favoriteIcon__L7bMI {
    width: 25px;
    height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/Panel/index.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".panelContainer {\n    background-color: rgb(20, 20, 20);\n    border-radius: 20px;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center;\n    max-width: 284px;\n}\n\n.panelTextContainer {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    text-align: left;\n}\n\n.panelTextContainer p {\n    margin: 0;\n}\n\n.panelImg {\n    width: 195px;\n    height: 234px;\n    object-fit: cover;\n}\n\n.redButton {\n    background-color: #F60109;\n    color: white;\n    border: none;\n    border-radius: 30px;\n    padding: 10px 30px;\n}\n\n.seeDetails {\n    background-color: black;\n    color: white;\n    border: none;\n    border-radius: 30px;\n    padding: 10px 30px;\n}\n\n.panelInfoTechnology {\n    display: flex;\n}\n\n.panelInfoTechnologyValue {\n    margin-top: 4px;\n    padding-left: 4px;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n    font-size: 11px;\n}\n\n.panelInfoId {\n    display: flex;\n    justify-content: space-between;\n}\n\n.favoriteIcon {\n    width: 25px;\n    height: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelContainer": `Panel_panelContainer__dEUQ8`,
	"panelTextContainer": `Panel_panelTextContainer__zi8Gz`,
	"panelImg": `Panel_panelImg__xfxaW`,
	"redButton": `Panel_redButton__hlFZy`,
	"seeDetails": `Panel_seeDetails__YMwY5`,
	"panelInfoTechnology": `Panel_panelInfoTechnology__Jk+Tz`,
	"panelInfoTechnologyValue": `Panel_panelInfoTechnologyValue__n3JUj`,
	"panelInfoId": `Panel_panelInfoId__ET-nW`,
	"favoriteIcon": `Panel_favoriteIcon__L7bMI`
};
export default ___CSS_LOADER_EXPORT___;
