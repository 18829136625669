// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: #000000;
    color: #fff;
    padding: 20px;
    width: 250px;
    font-family: Arial, sans-serif;
}

h2, h3 {
    margin-top: 0;
    text-align: left;
}

.mainTittle {
    font-weight: 700;
    font-size: 32px;
}

.popular-posts ul {
    list-style-type: none;
    padding: 0;
}

.post-item {
    padding: 10px;
    background-color: #121212;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    text-align: left;
}

.subscribe-section {
    background-color: #121212;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin-top: 20px;
}

.subscribe-section p {
    font-weight: 700;
    font-size: 18px;
}

.subscribe-button {
    background-color: #F60109;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
}

.tags-section {
    margin-top: 20px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    background-color: #121212;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid white;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Blog/Blog.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".sidebar {\n    background-color: #000000;\n    color: #fff;\n    padding: 20px;\n    width: 250px;\n    font-family: Arial, sans-serif;\n}\n\nh2, h3 {\n    margin-top: 0;\n    text-align: left;\n}\n\n.mainTittle {\n    font-weight: 700;\n    font-size: 32px;\n}\n\n.popular-posts ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.post-item {\n    padding: 10px;\n    background-color: #121212;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    cursor: pointer;\n    color: white;\n    text-align: left;\n}\n\n.subscribe-section {\n    background-color: #121212;\n    padding: 20px;\n    border-radius: 5px;\n    text-align: center;\n    margin-top: 20px;\n}\n\n.subscribe-section p {\n    font-weight: 700;\n    font-size: 18px;\n}\n\n.subscribe-button {\n    background-color: #F60109;\n    color: #fff;\n    border: none;\n    padding: 15px 30px;\n    border-radius: 20px;\n    cursor: pointer;\n    font-size: 18px;\n}\n\n.tags-section {\n    margin-top: 20px;\n}\n\n.tags {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.tag {\n    background-color: #121212;\n    color: #fff;\n    padding: 10px 20px;\n    border: 1px solid white;\n    border-radius: 20px;\n    margin-right: 5px;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
