// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolarPanelsPage_solarPanelsContainer__fbOv2 {
  display: flex;
}

.SolarPanelsPage_solarPanelsCardsContainer__bCxZF {
  padding: 20px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 892px;
  width: 100%;
}

.SolarPanelsPage_loader__eYGON { 
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/SolarPanelsPage/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".solarPanelsContainer {\n  display: flex;\n}\n\n.solarPanelsCardsContainer {\n  padding: 20px;\n  text-align: center;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 20px;\n  max-width: 892px;\n  width: 100%;\n}\n\n.loader { \n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solarPanelsContainer": `SolarPanelsPage_solarPanelsContainer__fbOv2`,
	"solarPanelsCardsContainer": `SolarPanelsPage_solarPanelsCardsContainer__bCxZF`,
	"loader": `SolarPanelsPage_loader__eYGON`
};
export default ___CSS_LOADER_EXPORT___;
