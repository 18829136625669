// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: black;
  color: white;
}

.sale {
    padding: 20px;
    text-align: center;
    background-color: black;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Sale/sale.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;EACzB","sourcesContent":["body {\n  background-color: black;\n  color: white;\n}\n\n.sale {\n    padding: 20px;\n    text-align: center;\n    background-color: black;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
