import { AboutPage } from "./AboutPage";
import { AdminPage } from "./AdminPage";
import { Layout } from "./Layout";
import PageNotFound from "./PageNotFound";
import HomePage from "./HomePage";
import Sale from "./Sale"
import SolarPanelsPage from "./SolarPanelsPage";
import { ProductPage } from "./ProductPage";

export { Layout, HomePage, AboutPage, AdminPage, PageNotFound, Sale, SolarPanelsPage, ProductPage };
