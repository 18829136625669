import React from 'react'
import './Help.css'

export default function Help() {
  return (
    <div className='Help'>Help
    <h1>Help</h1>
    </div>
  )
}
