// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #000000;
  color: #ffffff;
  padding: 2rem;
  text-align: left;
  width: 100%;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo img {
  max-width: 150px;
  margin-bottom: 1rem;
}

.footer-column {
  flex: 1 1;
  margin: 1rem;
}

.footer-column h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer-column p {
  margin: 0.5rem 0;
}

.footer-social {
  flex: 1 1;
  margin: 1rem;
}

.footer-social h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.footer-lang {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".footer {\n  background-color: #000000;\n  color: #ffffff;\n  padding: 2rem;\n  text-align: left;\n  width: 100%;\n}\n\n.footer-container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.footer-logo img {\n  max-width: 150px;\n  margin-bottom: 1rem;\n}\n\n.footer-column {\n  flex: 1;\n  margin: 1rem;\n}\n\n.footer-column h4 {\n  margin-bottom: 1rem;\n  font-size: 1.2rem;\n}\n\n.footer-column p {\n  margin: 0.5rem 0;\n}\n\n.footer-social {\n  flex: 1;\n  margin: 1rem;\n}\n\n.footer-social h4 {\n  margin-bottom: 1rem;\n  font-size: 1.2rem;\n}\n\n.social-icons {\n  display: flex;\n  gap: 1rem;\n}\n\n.social-icons img {\n  width: 24px;\n  height: 24px;\n}\n\n.footer-bottom {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 2rem;\n}\n\n.footer-lang {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
