// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shipping{
    padding: 20px;
    text-align: left;
}

.Shipping h1 {
    font-weight: 700;
    font-size: 36px;
}

.Shipping h2 {
    font-weight: 600;
    font-weight: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Shipping/Shipping.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".Shipping{\n    padding: 20px;\n    text-align: left;\n}\n\n.Shipping h1 {\n    font-weight: 700;\n    font-size: 36px;\n}\n\n.Shipping h2 {\n    font-weight: 600;\n    font-weight: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
