import React from 'react';
import './Accessories_electrical.css';

export default function Accessories_electrical() {
  return (
    <div className='Accessories_electrical'>
      <h1>Accessories Electrical</h1>
    </div>
  );
}
